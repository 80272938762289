import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {Part} from '../../open-api/generated-src';
import {useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {EmptyState} from "../table/table-config";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Input from "@amzn/awsui-components-react/polaris/input";
import {Founds} from "../Helper";




export interface Props {
    part: Part[];
}

const PartsCountingList: React.FC<Props> = props => {
// export default () => {
    const initPartDate: Part[]=[{
        serial_id: 'Loading',
        site: 'Loading',
        room: 'Loading,',
        bin: 'Loading',
        type_name: 'Loading',
        found: 'Loading'
    }]

    const [
        currentPageIndex,
        setCurrentPageIndex
    ] = React.useState(1);

    const paginationLabels = {
        nextPageLabel: 'Next page',
        pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
        previousPageLabel: 'Previous page'
    };

    const [value, setValue] = React.useState("");

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        props.part || [],
        {
            filtering: {
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 25
            },
            sorting: {},
            selection: {}
        }
    );


    return (
        <SpaceBetween size="l">
            <Input
                onChange={({ detail }) => setValue(detail.value)}
                value={value}
                placeholder="Scan RFID"
                autoFocus={true}
            />

            <Table
                {...collectionProps}
                columnDefinitions={[
                    {
                        id: "serial",
                        header: "Serial",
                        cell: e => e.serial_id,
                        width: 330,
                        minWidth: 150,
                        sortingField: "name",
                        isRowHeader: true
                    },
                    {
                        id: "bin",
                        header: "Bin",
                        cell: e => e.bin,
                        width: 170,
                        minWidth: 90
                    },
                    {
                        id: "type",
                        header: "Type",
                        cell: e => e.type_name,
                        width: 250,
                        minWidth: 100
                    },
                    {
                        id: "found",
                        header: "Found?",
                        cell: e => Founds(e.found as string),
                        width: 170,
                        minWidth: 90
                    }
                ]}
                items={items}
                resizableColumns
                header={
                    <Header>Cycle Counting Total: (0/{props.part.length})</Header>

                }
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            />

            <Table
                columnDefinitions={[
                    {
                        id: "serial",
                        header: "Serial",
                        cell: e => e.serial,
                        width: 330,
                        minWidth: 150,
                        sortingField: "name",
                        isRowHeader: true
                    },
                    {
                        id: "hexa",
                        header: "Hexa",
                        cell: e => e.hexa,
                        width: 170,
                        minWidth: 90,
                        sortingField: "type"
                    }
                ]}
                items={[
                    {
                        serial: "",
                        hexa: "",
                    }
                ]}
                loadingText="Loading resources"
                resizableColumns
                sortingDisabled
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No resources to display.
                        </Box>
                        <Button>Create resource</Button>
                    </Box>
                }
                header={
                    <Header>Extra Serials</Header>
                }
                pagination={
                    <Pagination currentPageIndex={1} pagesCount={1} />
                }
            />
        </SpaceBetween>
    );
}

export default PartsCountingList;