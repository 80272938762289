import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import {getBackendUser} from "../../auth/BackendUser";



export default function SelfTopNavigation() {
    const [searchText, setSearchText]=useState('')


    const [user, setUser] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setUser(await getBackendUser());
            }
            catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);



    return (
        <TopNavigation
            identity={{
                href: "#",
                title: "BJS Tools Set"
            }}
            utilities={[
                {
                    type: "button",
                    text: "Global Tools",
                    href: "https://aws.amazon.com",
                    external: true,
                    externalIconAriaLabel: "Open the Pain Point Wiki"
                },
                {
                    type: "button",
                    iconName: "notification",
                    title: "Notifications",
                    ariaLabel: "Notifications (unread)",
                    badge: true,
                    disableUtilityCollapse: false
                },
                {
                    type: "menu-dropdown",
                    iconName: "settings",
                    ariaLabel: "Settings",
                    title: "Settings",
                    items: [
                        {
                            id: "settings-org",
                            text: "Organizational settings"
                        },
                        {
                            id: "settings-project",
                            text: "Project settings"
                        }
                    ]
                },
                {
                    type: "menu-dropdown",
                    text: user,
                    description: user+'@amazon.com',
                    iconName: "user-profile",
                    items: [
                        {id: "profile", text: "Profile"},
                        {id: "preferences", text: "Preferences"},
                        {id: "security", text: "Security"},
                        {
                            id: "support-group",
                            text: "Support",
                            items: [
                                {
                                    id: "documentation",
                                    text: "Documentation",
                                    href: "#",
                                    external: true,
                                    externalIconAriaLabel:
                                        " (opens in new tab)"
                                },
                                {id: "support", text: "Support"},
                                {
                                    id: "feedback",
                                    text: "Feedback",
                                    href: "#",
                                    external: true,
                                    externalIconAriaLabel:
                                        " (opens in new tab)"
                                }
                            ]
                        },
                        {id: "signout", text: "Sign out"}
                    ]
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}