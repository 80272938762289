import * as React from "react";
import {useState} from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import {Part} from "../../open-api/generated-src";
import {CSVLink} from "react-csv";


export interface Props {
    part: Part[];
}
const CycleReason: React.FC<Props> = props => {
// export default () => {
    const initPartDate: Part[]=[{
        serial_id: 'Loading',
        site: 'Loading',
        room: 'Loading,',
        bin: 'Loading',
        type_name: 'Loading',
        found: 'Loading'
    }]

    function checkNumber(){
        console.log(brokenTags as number + missing as number + partsWithoutTags as number + storage as number + tagNotReadable as number + others as number)
        return Number(brokenTags) + Number(missing) + Number(partsWithoutTags) + Number(storage) + Number(tagNotReadable) + Number(others)
    }

    let timestamp = Date.parse(new Date().toString());

    const [brokenTags, setBrokenTags] = React.useState(0)
    const [missing, setMissing] = React.useState(0)
    const [partsWithoutTags, setPartsWithoutTags] = React.useState(0)
    const [storage, setStorage] = React.useState(0)
    const [tagNotReadable, setTagNotReadable] = React.useState(0)
    const [others, setOthers] = React.useState(0)

    const [value, setValue] = React.useState("");


    return (
        <form onSubmit={e => e.preventDefault()}>
            <Form
                errorText={
                    checkNumber() != props.part.length &&
                    "You need to explain for a total of 4 items"
            }
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container
                        header={
                            <Header variant="h3">
                                Reason
                            </Header>
                        }
                    >
                        <span>You need to explain for a total of {props.part.length} items</span>
                        <hr/>
                        <SpaceBetween direction="vertical" size="l">
                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Broken tags:">
                                </FormField>
                                <Input
                                    value={brokenTags.toString()}
                                    onChange={({ detail }) => setBrokenTags(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Missing:">
                                </FormField>
                                <Input
                                    value={missing.toString()}
                                    onChange={({ detail }) => setMissing(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Parts Without tags:">
                                </FormField>
                                <Input
                                    value={partsWithoutTags.toString()}
                                    onChange={({ detail }) => setPartsWithoutTags(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Storage:">
                                </FormField>
                                <Input
                                    value={storage.toString()}
                                    onChange={({ detail }) => setStorage(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Tag not readable:">
                                </FormField>
                                <Input
                                    value={tagNotReadable.toString()}
                                    onChange={({ detail }) => setTagNotReadable(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 2 }, {colspan: 8}]}
                            >
                                <FormField label="Others:">
                                </FormField>
                                <Input
                                    value={others.toString()}
                                    onChange={({ detail }) => setOthers(detail.value as unknown as number)}
                                    type={"number"}
                                />
                                <Input value={''} placeholder={'Commons'}/>
                            </Grid>

                        </SpaceBetween>
                    </Container>

                    <Container
                        header={
                            <Header variant="h3">
                                Extra Serials Reason(If Any):
                            </Header>
                        }
                    >
                        <Textarea
                            onChange={({ detail }) => setValue(detail.value)}
                            value={value}
                            placeholder="This is a placeholder"
                        />
                    </Container>

                    <Container
                        header={
                            <Header variant="h3">
                                Comments(If Any):
                            </Header>
                        }
                    >
                        <Textarea
                            onChange={({ detail }) => setValue(detail.value)}
                            value={value}
                            placeholder="This is a placeholder"
                        />
                    </Container>


                    <Button variant="primary">
                        <CSVLink
                            data={props.part}
                            filename={"missing_parts_"+timestamp.toString()+".csv"}
                        >
                            Download Missing Parts Details
                        </CSVLink>
                    </Button>

                </SpaceBetween>
            </Form>
        </form>
    );
}

export default CycleReason;