import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation/navigation";
import './styles.scss';

import QRCode from './qrcode';

export default function Homepage() {
  return (
      <AppLayout
          content={<QRCode/>}
          contentType="default"
          navigationHide={false}
          navigation={<ServiceNavigation />}
          toolsHide={true}
          disableContentPaddings={false}
      />
  );
}
