import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import Homepage from '../components/home';
import CycleCount1LocationSelectionPage from "../components/cycleCount";
import SerialsToHex from '../components/serialsToHex'
import QRTools from "../components/QRTools";
import REDBIN from "../components/redMediaBin"
import CycleCountResult from "../components/cycleCountResult";

export default function IndexRouter() {
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/home" component={Homepage}/>
                <Route
                    exact
                    path="/cycle_count/select_location"
                    component={CycleCount1LocationSelectionPage}
                />
                <Route
                    exact
                    path="/serialsToHexs"
                    component={SerialsToHex}
                />
                <Route
                    exact
                    path="/qr_code"
                    component={QRTools}
                />
                <Route
                    exact
                    path="/red_bin"
                    component={REDBIN}
                />
                <Route
                    exact
                    path="/cycle_count_result"
                    component={CycleCountResult}
                />

                <Redirect from='/' to='/home'/>
            </Switch>
        </HashRouter>
    );
}