import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation/nav_rfid";
import './styles.scss';
// import CycleCount1LocationSelectionPage from './CycleCount1LocationSelectionPage';
import SerialsToHex from './SerialsToHex';

export default function Homepage() {
  return (
      <AppLayout
          content={<SerialsToHex/>}
          contentType="default"
          navigationHide={false}
          navigation={<ServiceNavigation />}
          toolsHide={true}
          disableContentPaddings={false}
      />
  );
}
