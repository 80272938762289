import ApiFactory from '../open-api/ApiFactory';

let user: string;

export async function getBackendUser() {
    if (! user) {
        const api = ApiFactory();
        user = (await api.whoAmI()).data.identity;
    }
    return user;
}
