import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation/nav_rfid";
import './styles.scss';
// import CycleCount1LocationSelectionPage from './CycleCount1LocationSelectionPage';
import CycleCountResult from './cycleCountResult';

export default function Homepage() {
  return (
      <AppLayout
          content={<CycleCountResult/>}
          contentType="dashboard"
          navigationHide={false}
          navigation={<ServiceNavigation />}
          toolsHide={false}
          disableContentPaddings={false}
      />
  );
}
