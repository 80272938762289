import * as React from "react";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Clock from '../Clock'
import {ButtonDropdown, Toggle} from "@amzn/awsui-components-react";
import useStateRef from "react-usestateref";
import {applyMode, Mode} from "@amzn/awsui-global-styles";
import {truncateString, PhoneToolImg, supportLinks, useLocalStorage, checkStage, PhoneToolBigImg} from '../Helper'
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {useEffect, useState} from "react";
import { getMidwayUser } from '../../auth/MidwayJwtToken';

export default () => {
    const [checked, setChecked, refChecked] = useStateRef(false)
    const [_, setToastifyTheme] = useLocalStorage('toastifyTheme', 'light')



    const [user, setUser] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setUser(await getMidwayUser());
            }
            catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);


    const [UIChecked, setUIChecked, refUIChecked] = useStateRef(true)
    const checkCurrentTheme = () => {
        const GLOBAL_THEME = localStorage.getItem('globalTheme')
        if (GLOBAL_THEME === 'true') {
            setToastifyTheme('dark')
            applyMode(Mode.Dark)
            setChecked(true)
        } else if (GLOBAL_THEME === null) {
            setToastifyTheme('light')
            applyMode(Mode.Light)
            setChecked(false)
        } else {
            setToastifyTheme('light')
            applyMode(Mode.Light)
            setChecked(false)
        }
    }

    const switchUI = (e: any) => {
        localStorage.setItem('UIMode', String(e))
        setUIChecked(e)
        checkCurrentUI()
    }

    const checkCurrentUI = () => {
        const UI = localStorage.getItem('UIMode')
        if (UI === 'true') {
            setUIChecked(true)
            document.body.classList.add('awsui-visual-refresh')
        } else if (UI === null) {
            setUIChecked(true)
            document.body.classList.add('awsui-visual-refresh')
        } else {
            setUIChecked(false)
            document.body.classList.remove('awsui-visual-refresh')
        }
    }

    const switchTheme = (e: any) => {
        localStorage.setItem('globalTheme', String(e))
        setChecked(e)
        checkCurrentTheme()
        checkCurrentUI()
    }


    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        info={<Link>Info</Link>}
                        description="Please contact BJS Dev (currenty qiguowe@) for features and maintainence"

                    >
                        BJS Tools Set
                    </Header>


                </SpaceBetween>
            }
        >
            <Container
                header={

                    <Header>
                        Welcome to BJS Tools Set Platform
                        <Clock />
                    </Header>
                }
            >
                <Grid>
              <span key='mode-changes'>
              <Toggle
                  key='UI-checked'
                  checked={
                      refUIChecked.current !== UIChecked ? refUIChecked.current : UIChecked
                  }
                  onChange={({detail}) => switchUI(detail.checked)}
              >
                  Refresh UI
              </Toggle>
              <Toggle
                  key='night-mode'
                  checked={
                      refChecked.current !== checked ? refChecked.current : checked
                  }
                  onChange={({detail}) => switchTheme(detail.checked)}
              >
                  Night mode
              </Toggle>
              </span>
                    <span key='phonetool-image'>{PhoneToolBigImg(String(user))}</span>
                    <span key='alias'>
                        Hi {user}
                    </span>
                </Grid>
            </Container>
        </ContentLayout>
    );
}