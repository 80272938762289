import * as React from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import {useRef} from "react";

export default (type1, type2) => {
    const [phistonName, setPhistonnName] = React.useState("");
    const [phistonNameDisable, setPhistonNameDisable] = React.useState(false);
    const [phistonNameFocus, setPhistonNameFocus] = React.useState(true);

    async function setPhistonName_v2(key){
        if (key.keyCode == 13)
        {
            if (phistonName != ""){
                setPhistonNameFocus(false)
                setPhistonNameDisable(true)
                //BinLocationFocus.current?.focus()
            }
        }
    }

    return (
        <div style={{width: 'calc(100% - 35px)',height: 'calc(100% - 15px)'}}>
            <SpaceBetween direction="horizontal" size="xxl" >
                <SpaceBetween size="m">
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link">
                                        Cancel
                                    </Button>
                                    <Button variant="primary">Submit</Button>
                                </SpaceBetween>
                            }
                        >
                            <Container
                                header={
                                    <Header variant="h2">
                                        Please enter the following information for {type1}
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Phiston Name/Asset">
                                        <Input
                                            onChange={({ detail }) => setPhistonnName(detail.value)}
                                            onKeyDown={({ detail  }) => setPhistonName_v2(detail)}
                                            value={phistonName}
                                            autoFocus = {phistonNameFocus}
                                            disabled={phistonNameDisable}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                        </Form>
                    </form>
                </SpaceBetween>

                <SpaceBetween size="s">
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link">
                                        Cancel
                                    </Button>
                                    <Button variant="primary">Submit</Button>
                                </SpaceBetween>
                            }
                        >
                            <Container
                                header={
                                    <Header variant="h2">
                                        Please enter the following information for {type2}
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Phiston Name/Asset">
                                        <Input
                                            value={""}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                        </Form>
                    </form>
                </SpaceBetween>
            </SpaceBetween>

        </div>

    );
}