import React from 'react'
import useStateRef from 'react-usestateref'
import Popover from '@amzn/awsui-components-react/polaris/popover'
import Badge from '@amzn/awsui-components-react/polaris/badge'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'
import Thumbnail from '@amzn/meridian/thumbnail'
import moment from 'moment'
import Grid from '@amzn/awsui-components-react/polaris/grid'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'
import Icon from "@amzn/awsui-components-react/polaris/icon";

export function checkStage () {
    // In Dev
    const port = location.port
    if (port === '4321') return 'Dev'

    // Switcher
    const origin = window.location.origin
    switch (origin){
        default:
            return 'Prod'
        case 'https://beta.rzp.dcsa.aws.dev':
        case 'https://nguynnn.rzp.dcsa.aws.dev':
        case 'https://rkamioka.rzp.dcsa.aws.dev':
            return 'Beta'
        case 'https://gamma.rzp.dcsa.aws.dev':
            return 'Gamma'
    }
}


export function PhoneToolImg (alias: string) {
    return <SpaceBetween size='xl'>
        <Popover
            dismissButton={false}
            position='top'
            size='large'
            triggerType='custom'
            content={
                <span>
          {alias}
        </span>
            }
        >
            <Thumbnail
                source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
                borderColor='#552583'
                type='square'
                size='small'
            />
        </Popover>
    </SpaceBetween>
}

export function PhoneToolBigImg (alias: string) {
    return <SpaceBetween direction='horizontal' size='xs'>
        <Popover
            dismissButton={false}
            position='bottom'
            size='small'
            triggerType='custom'
            content={
                <span>
          {alias}
        </span>
            }
        >
            <Thumbnail
                source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
                borderColor='#552583'
                type='square'
                size='large'
            />
        </Popover>
    </SpaceBetween>
}


// NOT USED
// export function PhoneToolImgWithNameAndTitle (alias: string, name: string, title: string) {
//   return <span className='cards'>
//     <Thumbnail
//       source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
//       borderColor='grey'
//       type='square'
//       size='small'
//     />{' '}
//     <span><b>{name}</b> | {title}</span>
//   </span>
// }



export function IMStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case 'Draft' || 'Deleted':
            return <Badge>Draft</Badge>
        case 'Approved':
            return <Badge color='green'>Approved</Badge>
        case 'Pending Review':
            return <Badge color='blue'>
                <Spinner></Spinner> Pending Review
            </Badge>
        case 'Rejected':
            return <Badge color='red'>Rejected</Badge>
    }
}

export function typeStatus (string: string) {
    switch (string) {
        default:
            return <Badge>{string}</Badge>
        case 'None' || '':
            return '-'
        case 'Parts':
            return <Badge color='blue'>{string}</Badge>
        case 'Tools':
            return <Badge color='grey'>{string}</Badge>
    }
}


export function scanStatus (type: string) {
    switch (type) {
        default:
            return type
        case 'None' || '':
            return '-'
        case 'Media Break Fix':
            return <Badge color='green'>{type}</Badge>
        case 'Decom':
            return <Badge>{type}</Badge>
        case 'Phiston':
            return <Badge color='blue'>{type}</Badge>
        case 'Pending Review':
            return <Badge>{type}</Badge>
        case 'Rejected':
            return <Badge color='red'>{type}</Badge>
    }
}


export function truncateString (string: string, number: any) {
    if (string.length <= number) {
        return string
    } else {
        return <Popover
            dismissButton={false}
            position='top'
            size='small'
            content={string}
        >
            <small>{string.slice(0, number) + '.... ' }</small>
        </Popover>
    }
}

export function decorateStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case '4-Normal':
            return <Badge>{status}</Badge>
        case '3-Urgent':
            return <Badge color='blue'>{status}</Badge>
        case '2-SEV2':
            return <Badge color='red'>{status}</Badge>
        case '1-Large Scale Event':
            return <Badge color='red'>{status}</Badge>
    }
}


function pad(num: number) {
    return ('0' + num).slice(-2);
}

export function hhmmss(secs: number) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${hours === 0 ? '' : pad(hours) + 'hour(s)'}
    ${minutes === 0 ? '' : pad(minutes) + 'min(s) '}
    ${pad(secs) + 'sec(s)'}`;
}

export const approvalStatusProps = {
    pending: 'false',
    approved: 'true'
}

export function Confirmed (item: string) {
    switch (item) {
        default:
            return item
        case 'None' || '':
            return '-'
        case approvalStatusProps.pending:
            return <Badge color='blue'>{item}</Badge>
        case approvalStatusProps.approved:
            return <Badge color='green'>{item}</Badge>
    }
}

export const myDecisionProps = {
    approval_required: 'Approval required',
    future_approval_required: 'Future approval required',
    completed: 'Completed',
    no_action_required: 'No action required'
}

// export function convertUnixToStringCGFReport (record: any) {
//   let tempDate = new Date(parseInt(record.actualstart) * 1000)
//   record.actualstart =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   tempDate = new Date(parseInt(record.actualend) * 1000)
//   record.actualend =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   tempDate = new Date(parseInt(record.requestdatetime) * 1000)
//   record.requestdatetime =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   return record
// }

export function generalConvertToUnix (unixTime: any) {
    const tempTime = new Date(parseInt(unixTime) * 1000)
    const minutes = String(tempTime.getMinutes());
    const seconds = String(tempTime.getSeconds());
    unixTime = `${tempTime.getFullYear()}/${1 + tempTime.getMonth()}/${tempTime.getDate()} ${tempTime.getHours()}:${minutes}:${seconds}`
    return unixTime
}

export function toCamelCase (str: string) {
    let camelString = ''
    for (let idx = 0; idx < str.length; idx++) {
        if (idx === 0) {
            camelString = str[idx].toUpperCase()
        } else {
            camelString = camelString + str[idx].toLowerCase()
        }
    }
    return camelString
}

export function Founds (item: string) {
    switch (item) {
        default:
            return item
        case 'None' || '':
            return '-'
        case 'Missing':
            return <Icon name="status-pending"/>
        case 'Found':
            return <Badge color='green'>{item}</Badge>
    }
}

export const GET_FILTER_COUNTER_TEXT = (count: number | undefined) => `${count} ${count === 1 ? 'match' : 'matches'}`

export const PAGINATION_LABELS = {
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber: any) => `Page ${pageNumber} of all pages`
}

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',

    filteringPlaceholder: 'Search',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',

    operationAndText: 'and',
    operationOrText: 'or',

    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',

    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All properties',

    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: (text: any) => `Use: '${text}'`
}

export function LoginBadge (status: string) {
    switch (status) {
        default:
            return <Badge>{status}</Badge>
    }
}

export function isStringEmpty (str: string) {
    let isStringEmpty: boolean = false
    if (typeof str === 'string' && str.trim().length === 0) isStringEmpty = true
    if (!str) isStringEmpty = true
    if (str === 'None') isStringEmpty = true
    if (str === 'null') isStringEmpty = true
    return isStringEmpty
}

export function useLocalStorage(key: any, initialValue: any) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useStateRef(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

export const supportLinks = {
    'analytics_link': {
        'id': 'analytics_link',
        'label': 'Analytics-related issues or requests',
        'link': 'https://issues.amazon.com/issues/create?template=c398ff37-ead6-477a-85bc-72ecdb543cea'
    },
    'suggest_new_tool': {
        'id': 'suggest_new_tool',
        'label': 'Request a new tool',
        'link': 'https://issues.amazon.com/issues/create?template=a6982bdd-978a-43bc-bd6c-fa747d865da8'
    },
    'suggest_new_features': {
        'id': 'suggest_new_features',
        'label': 'Suggest a new feature',
        'link': 'https://issues.amazon.com/issues/create?template=059759e6-9e6c-4236-b49a-6fdc97b15414'
    },
    'report_bugs': {
        'id': 'report_bugs',
        'label': 'Report a bug',
        'link': 'https://issues.amazon.com/issues/create?template=2a4123b5-97c4-4e53-9705-ea1e5f9da71b'
    },
    'request_adding_location': {
        'id': 'request_adding_location',
        'label': 'Adding new/missing location',
        'link': 'https://issues.amazon.com/issues/create?template=77f2573d-f6c0-4341-bb1c-e21fe2767817'
    },
    'road_map': {
        'id': 'road_map',
        'label': 'Product Roadmap',
        'link': 'https://w.amazon.com/bin/view/Red_Zone_Experience/#HTeamRoadMap'
    },
    'wiki': {
        'id': 'wiki',
        'label': 'Team Wiki',
        'link': 'https://w.amazon.com/bin/view/Red_Zone_Experience/'
    },
    'security-access': {
        'id': 'security-access',
        'label': 'Security Operation TEAM',
        'link': 'https://permissions.amazon.com/a/search/single_criteria?searchString=dc-security-&criteria=TeamName&recordLimit=1000&requestFor=Individual'
    },
    'guard-access': {
        'id': 'guard-access',
        'label': 'Contact Guard Force TEAM',
        'link': 'https://permissions.amazon.com/a/search/single_criteria?searchString=dc-security-*&criteria=TeamName&recordLimit=2000&requestFor=Individual'
    }
}

// CSS