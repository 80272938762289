import * as React from "react";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Select from "@amzn/awsui-components-react/polaris/select";
import QRCode from 'react-qr-code';
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import {SetStateAction} from "react";


export default () => {
    const [value, setValue] = React.useState("");
    const [
        selectedOption,
        setSelectedOption
    ] = React.useState({ label: "Q (RECOMMEND)", value: "Q" });

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        info={<Link>Info</Link>}
                        description="This is a internal tool for creating custom QR codes."

                    >
                        QR Code Generator
                    </Header>
                </SpaceBetween>
            }
        >

            <SpaceBetween direction="horizontal" size="l">
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                description="Please input the commands or URLs to Generator QR Code"
                            >
                                Input
                            </Header>
                        }
                        footer={
                            <ExpandableSection
                                header="Additional settings"
                                variant="footer"
                            >
                                <Select
                                    selectedOption={selectedOption}
                                    onChange={({ detail }) =>
                                        setSelectedOption(detail.selectedOption as SetStateAction<{ label: string; value: string; }>)
                                    }
                                    options={[
                                        { label: "L", value: "L" },
                                        { label: "M", value: "M" },
                                        { label: "Q (RECOMMEND)", value: "Q" },
                                        { label: "H", value: "H" }
                                    ]}
                                />
                            </ExpandableSection>
                        }
                    >
                        <FormField
                            label="COMMANDS OR URL(S)"
                            constraintText="For multiple commands, enter each command on a new line"
                        >
                        <Textarea
                            onChange={({ detail }) => setValue(detail.value)}
                            value={value}
                            rows={7}
                            placeholder="This is a placeholder"
                        />
                        </FormField>
                    </Container>
                </SpaceBetween>

                <SpaceBetween size="l">
                    <Container
                        header={
                        <Header
                            variant="h2"
                            description="QR codes can be created using any string of text"
                        >
                            QR Code
                        </Header>
                    }
                        >
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={value}
                            viewBox={`0 0 256 256`}
                            level={selectedOption.value}

                        />
                </Container>
                </SpaceBetween>

            </SpaceBetween>

        </ContentLayout>
    );
}