import * as React from "react";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import MediaBreakFix from "./mediaBreakFix"
import Shreding from "./shreding";
import Overview from "./Overview"
import Input from "@amzn/awsui-components-react/polaris/input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {PhoneToolBigImg} from "../Helper";

export default () => {
    const [verifier, setVerifier] = React.useState("");
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        description="This is a tool for Red Seal Record."
                        actions={
                        <SpaceBetween direction="horizontal" size="m">
                            <span key='phonetool-image'>{PhoneToolBigImg(String(verifier))}</span>
                            <span>
                                <FormField
                                    description="Verifier Login"
                                    label="Please input verifier login."
                                >
                                <Input
                                    value={verifier}
                                    onChange={event =>
                                        setVerifier(event.detail.value)
                                    }
                                    placeholder="Enter verifier login"
                                />
                            </FormField>
                            </span>
                        </SpaceBetween>

                        }
                    >
                        Seal Record
                    </Header>
                </SpaceBetween>
            }
        >
            <Tabs
                tabs={[
                    {
                        label: "Media Break Fix",
                        id: "mediabreakfix",
                        content: MediaBreakFix("Media Break Fix", verifier)
                    },
                    {
                        label: "Decom",
                        id: "decom",
                        content: MediaBreakFix("Decom", verifier)
                    },
                    {
                        label: "Shredding",
                        id: "shredder",
                        content: Shreding("Phiston", "SSD E-Waste")
                    },
                    {
                        label: "E-waste Pickup",
                        id: "ewaste",
                        content: "Third tab content area"
                    },
                    {
                        label: "Overview",
                        id: "overview",
                        content: Overview()
                    }
                ]}
                variant="container"
            />
        </ContentLayout>
    );
}