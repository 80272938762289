import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest, QueryParameterBag } from '@aws-sdk/types';
import getAwsCredentials from './AwsCredentialsFromMidwayToken'

// Axios interceptor that adds sigv4 headers to api calls
export default async (config: AxiosRequestConfig) => {
    const configUrl = config.url!;
    if (configUrl == undefined || configUrl.startsWith('/') || configUrl.startsWith('#')) { // Ignore
        return config;
    }

    let apiUrl: URL;
    try {
        apiUrl = new URL(configUrl);
    } catch(e) {
        console.log('Unable to build URL.', e);
        return config;
    }

    const { hostname, pathname, searchParams, protocol, port } = apiUrl;
    const { data, headers, method } = config;

    // Remove all the default Axios headers and leave the rest in headersToSign
    const {
        common,
        delete: _delete, // 'delete' is a reserved word
        get,
        head,
        post,
        put,
        patch,
        ...headersToSign
    } = headers as AxiosRequestHeaders;

    const region = window.location.host != 'tools-bjs.aws.a2z.org.cn' ? ( window.location.host != 'qiguowe.amazon.com:4443' ? 'us-east-1' :'cn-north-1'):'cn-north-1';
    console.log('cn-north-1', region)
    // The signer
    const sigv4 = new SignatureV4({
        service: 'execute-api',
        region: region,
        credentials: await getAwsCredentials(),
        sha256: Sha256,
    });
    console.log("sigv4", sigv4)

    const queryBag: QueryParameterBag = {};
    for(const key of searchParams.keys()) {
        queryBag[key] = searchParams.getAll(key);
    }

    // Sign the request
    const signedRequest = await sigv4.sign({
        method: method!.toUpperCase(),
        protocol,
        hostname,
        port: +port,
        path: pathname,
        query: queryBag,
        body: data,
        headers: {
            ...headersToSign,
            // The host is required by the sigv4.sign method.
            // Ref: https://docs.aws.amazon.com/IAM/latest/UserGuide/signing-elements.html#endpoint-specification
            'host': hostname
        }
    } as HttpRequest);

    // Remove unsafe header so Chrome does not complain
    delete signedRequest.headers['host'];

    // Replace the original request headers
    config.headers = signedRequest.headers;

    return config;
}