import * as React from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import {useEffect, useRef, useState} from "react";
import {getBackendUser} from "../../auth/BackendUser";
import { RedMediaBinManagement } from '../../open-api/generated-src';
import ApiFactory from "../../open-api/ApiFactory";

export default (type, verifier) => {
    const [ticket, setTicket] = React.useState("");
    const [binName, setBinName] = React.useState("");
    const [binLocation, setBinLocation] = React.useState("");
    const [oleSeal, setOldSeal] = React.useState("");
    const [newSeal, setNewSeal] = React.useState("");
    const [mediaCount, setMediaCount] = React.useState("");
    const [primary, serPrimary] = useState('');

    useEffect(() => {
        (async () => {
            try {
                serPrimary(await getBackendUser());
            }
            catch(error: any) {
                serPrimary('Unknown User')
            }
        })();
    }, []);

    const initialRedBinDate: RedMediaBinManagement = {
        BinLocation: binLocation,
        BinNameAsset: binName,
        CreateDate: 'null',
        MediaCount: mediaCount,
        NewSealNumber: newSeal,
        OldSealNumber: oleSeal,
        Primary: primary,
        Ticket: ticket,
        TPVR: false,
        Type: type,
        UUID: "277adf79-9b3b-426c-8257-7a332f4fb353",
        Verifier: verifier,
        Old_New_Seal: 'test'
    };

    const [redMediaBinManagementDate, setRedMediaBinManagementDate] = useState(initialRedBinDate);
    const RedMediaBinManagement = ApiFactory();

    async function setBinName_v2(key){
        if (key.keyCode == 13)
        {
            if (binName != ""){
                console.log(type)
                setBinNameFocus(false)
                setBinNameDisable(true)
                BinLocationFocus.current?.focus()
            }
        }
    }
    async function setBinLocation_v2(key){
        if (key.keyCode == 13) {
            if (binLocation != ""){
                OldSealFocus.current?.focus()
            }
        }
    }
    async function setOldSeal_v2(key){
        if (key.keyCode == 13) {
            if (oleSeal != ""){
                NewSealFocus.current?.focus()
            }
        }
    }
    async function setNewSeal_v2(key){
        if (key.keyCode == 13) {
            if (newSeal != ""){
                MediaCountFocus.current?.focus()
            }
        }
    }
    async function setMediaCount_v2(key){
        if (key.keyCode == 13) {
            if (mediaCount != ""){

            }
        }
    }

    const [error, serError] = useState('');

    const [binNameFocus, setBinNameFocus] = React.useState(true);


    const BinLocationFocus = useRef<HTMLInputElement>(null);
    const OldSealFocus = useRef<HTMLInputElement>(null);
    const NewSealFocus = useRef<HTMLInputElement>(null);
    const MediaCountFocus = useRef<HTMLInputElement>(null);

    const [binNameDisable, setBinNameDisable] = React.useState(false);

    async function cancel(){
        setBinName('')
        setBinLocation('')
        setTicket('')
        setOldSeal('')
        setNewSeal('')
        setMediaCount('')
    }
    async function save() {
        try {
            serError("")
            if (verifier == ""){
                serError("Please input verifier login.")
            }
            else if (verifier == primary){
                serError("Please input correct verifier login.")
            }
            else if(oleSeal == ''){
                setOldSeal("null")
            }
            else if(binName == ''){
                serError("Please input Bin Name/Asset.")
            }
            else if(binLocation == ''){
                serError("Please input Bin Location")
            }
            else if(newSeal == ''){
                serError("Please Scan New Seal Number")
            }
            else if(mediaCount == ''){
                serError("Please enter the number of media will be placed in the Bin")
            }
            else if(ticket == ''){
                serError("Please input Ticket number")
            }
            else {
                RedMediaBinManagement.createRedMediaBinManagement(initialRedBinDate)
            }

        }
        catch(error: any) {
            console.log(error?.response?.data?.message || 'Unknown error');
        }
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={save}>Submit</Button>
                    </SpaceBetween>
                }
                errorText={error}
            >
                <Container
                    header={
                        <Header variant="h2">
                            Please enter the following information for {type}
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Bin Name/Asset">
                            <Input
                                onChange={({ detail }) => setBinName(detail.value)}
                                onKeyDown={({ detail  }) => setBinName_v2(detail)}
                                value={binName}
                                autoFocus ={binNameFocus}
                                disabled={binNameDisable}
                                ariaRequired={true}
                            />
                        </FormField>
                        <FormField label="Bin Location"
                                   constraintText={
                                       <>
                                           The name like BJS11.1-1
                                       </>
                                   }>
                            <Input
                                ref={BinLocationFocus}
                                onChange={({ detail }) => setBinLocation(detail.value)}
                                onKeyDown={({ detail  }) => setBinLocation_v2(detail)}
                                value={binLocation}
                                ariaRequired={true}
                            />
                        </FormField>
                        <FormField label="Scan Old Seal Number">
                            <Input
                                ref={OldSealFocus}
                                onChange={({ detail }) => setOldSeal(detail.value)}
                                onKeyDown={({ detail  }) => setOldSeal_v2(detail)}
                                value={oleSeal}
                            />
                        </FormField>
                        <FormField label="Scan New Seal Number">
                            <Input
                                ref={NewSealFocus}
                                onChange={({ detail }) => setNewSeal(detail.value)}
                                onKeyDown={({ detail  }) => setNewSeal_v2(detail)}
                                value={newSeal}
                                ariaRequired={true}
                            />
                        </FormField>
                        <FormField label="Please enter the number of media will be placed in the Bin">
                            <Input
                                ref={MediaCountFocus}
                                onChange={({ detail }) => setMediaCount(detail.value)}
                                onKeyDown={({ detail  }) => setMediaCount_v2(detail)}
                                value={mediaCount}
                                ariaRequired={true}
                            />
                        </FormField>
                        <FormField label="Ticket">
                            <Input
                                onChange={({ detail }) => setTicket(detail.value)}
                                value={ticket}
                                ariaRequired={true}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
}