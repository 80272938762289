// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import sigv4Interceptor from './auth/AxiosSigv4Interceptor';
import { initializeAppSettings } from './config/AppSettings';


// Add authorization header to all (axios) api calls
axios.interceptors.request.use(sigv4Interceptor);

(async () => {
  // Make sure Midway is present before rendering
  const midwayToken = await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

 // const MIDWAY_URL = window.location.host == 'tools-bjs.aws.a2z.org.cn' ? 'https://midway-auth.amazon.com/SSO' : 'https://midway-auth.aws-border.cn/SSO';


 const url = window.location.host == 'qiguowe.people.aws.a2z.org.cn' ? 'https://1gv6c6urhc.execute-api.cn-north-1.amazonaws.com.cn/prod/stats' : 'https://0wa18psdfk.execute-api.us-east-1.amazonaws.com/prod/stats';
 // const url = 'https://1gv6c6urhc.execute-api.cn-north-1.amazonaws.com.cn/prod/stats';
      // REMOVE: This is just an example on how to record web stats

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
})();
