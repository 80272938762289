export interface IAppSettings {
  readonly apiUrl: string;
  readonly assumeRoleArn: string;
}

let cache: IAppSettings = {
  apiUrl: '',
  assumeRoleArn: ''
};

export function getAppSetting(key: string): string {
  return cache[key];
}

// This needs to be a function. If declared as constant, the cache stays with empty values
export const AppSettings = (): IAppSettings => cache;

export function initializeAppSettings(settings: IAppSettings) {
  cache = { ...settings };
  Object.freeze(cache);
}
