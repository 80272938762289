/* tslint:disable */
/* eslint-disable */
/**
 * An amazing API for SmartRFIDforBJSModel
 * An example service, with CRUD operations
 *
 * The version of the OpenAPI document: 2023-04-01
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateLocationRequestContent
 */
export interface CreateLocationRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateLocationRequestContent
     */
    'Room': string;
}
/**
 * 
 * @export
 * @interface CreateLocationResponseContent
 */
export interface CreateLocationResponseContent {
    /**
     * 
     * @type {Location}
     * @memberof CreateLocationResponseContent
     */
    'created_location': Location;
}
/**
 * Pet attributes
 * @export
 * @interface CreatePetRequestContent
 */
export interface CreatePetRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePetRequestContent
     */
    'tag'?: string;
}
/**
 * Created Pet
 * @export
 * @interface CreatePetResponseContent
 */
export interface CreatePetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof CreatePetResponseContent
     */
    'created_pet': Pet;
}
/**
 * 
 * @export
 * @interface CreateRedMediaBinManagementRequestContent
 */
export interface CreateRedMediaBinManagementRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'BinLocation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'BinNameAsset': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'CreateDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'MediaCount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'NewSealNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'OldSealNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'Primary': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'Ticket': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'TPVR': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'Type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'UUID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedMediaBinManagementRequestContent
     */
    'Verifier': string;
}
/**
 * 
 * @export
 * @interface CreateRedMediaBinManagementResponseContent
 */
export interface CreateRedMediaBinManagementResponseContent {
    /**
     * 
     * @type {RedMediaBinManagement}
     * @memberof CreateRedMediaBinManagementResponseContent
     */
    'create_red_media_bin_management': RedMediaBinManagement;
}
/**
 * 
 * @export
 * @interface CreateSerialsToHexRequestContent
 */
export interface CreateSerialsToHexRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateSerialsToHexRequestContent
     */
    'hex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSerialsToHexRequestContent
     */
    'Serials': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSerialsToHexRequestContent
     */
    'CreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSerialsToHexRequestContent
     */
    'Printer': string;
}
/**
 * 
 * @export
 * @interface CreateSerialsToHexResponseContent
 */
export interface CreateSerialsToHexResponseContent {
    /**
     * 
     * @type {SerialsToHex}
     * @memberof CreateSerialsToHexResponseContent
     */
    'created_serials_to_hex': SerialsToHex;
}
/**
 * 
 * @export
 * @interface GetLocationResponseContent
 */
export interface GetLocationResponseContent {
    /**
     * 
     * @type {Location}
     * @memberof GetLocationResponseContent
     */
    'location'?: Location;
}
/**
 * The pet identified by petId
 * @export
 * @interface GetPetResponseContent
 */
export interface GetPetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof GetPetResponseContent
     */
    'pet'?: Pet;
}
/**
 * 
 * @export
 * @interface GetRedMediaBinManagementResponseContent
 */
export interface GetRedMediaBinManagementResponseContent {
    /**
     * 
     * @type {RedMediaBinManagement}
     * @memberof GetRedMediaBinManagementResponseContent
     */
    'red_media_bin_management'?: RedMediaBinManagement;
}
/**
 * 
 * @export
 * @interface GetSerialsToHexResponseContent
 */
export interface GetSerialsToHexResponseContent {
    /**
     * 
     * @type {SerialsToHex}
     * @memberof GetSerialsToHexResponseContent
     */
    'serials_to_hex'?: SerialsToHex;
}
/**
 * 
 * @export
 * @interface GotPartsListByRoomResponseContent
 */
export interface GotPartsListByRoomResponseContent {
    /**
     * 
     * @type {Array<Part>}
     * @memberof GotPartsListByRoomResponseContent
     */
    'parts': Array<Part>;
}
/**
 * 
 * @export
 * @interface GotPartsListResponseContent
 */
export interface GotPartsListResponseContent {
    /**
     * 
     * @type {Array<Part>}
     * @memberof GotPartsListResponseContent
     */
    'parts': Array<Part>;
}
/**
 * Invalid input
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof InvalidInputExceptionResponseContent
     */
    'message'?: string;
}
/**
 * The identity of the caller
 * @export
 * @interface JustToCallResponseContent
 */
export interface JustToCallResponseContent {
    /**
     * 
     * @type {string}
     * @memberof JustToCallResponseContent
     */
    'call': string;
}
/**
 * 
 * @export
 * @interface ListLocationsResponseContent
 */
export interface ListLocationsResponseContent {
    /**
     * Location list
     * @type {Array<Location>}
     * @memberof ListLocationsResponseContent
     */
    'locations': Array<Location>;
}
/**
 * Pet list
 * @export
 * @interface ListPetsResponseContent
 */
export interface ListPetsResponseContent {
    /**
     * Pet list
     * @type {Array<Pet>}
     * @memberof ListPetsResponseContent
     */
    'pets': Array<Pet>;
}
/**
 * 
 * @export
 * @interface ListRedMediaBinManagementResponseContent
 */
export interface ListRedMediaBinManagementResponseContent {
    /**
     * SerialsToHex list
     * @type {Array<RedMediaBinManagement>}
     * @memberof ListRedMediaBinManagementResponseContent
     */
    'redMediaBinManagements': Array<RedMediaBinManagement>;
}
/**
 * 
 * @export
 * @interface ListSerialsToHexsResponseContent
 */
export interface ListSerialsToHexsResponseContent {
    /**
     * SerialsToHex list
     * @type {Array<SerialsToHex>}
     * @memberof ListSerialsToHexsResponseContent
     */
    'serialsToHexs': Array<SerialsToHex>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'Room': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'locationId': string;
}
/**
 * Resource does not exist
 * @export
 * @interface NoSuchResourceResponseContent
 */
export interface NoSuchResourceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof NoSuchResourceResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Part
 */
export interface Part {
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'serial_id': string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'room'?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'bin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'found'?: string;
}
/**
 * A Pet with identifier and attributes
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'tag'?: string;
    /**
     * Pet identifier
     * @type {string}
     * @memberof Pet
     */
    'petId': string;
}
/**
 * 
 * @export
 * @interface RedMediaBinManagement
 */
export interface RedMediaBinManagement {
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'BinLocation': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'BinNameAsset': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'CreateDate': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'MediaCount': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'NewSealNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'OldSealNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'Primary': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'Ticket': string;
    /**
     * 
     * @type {boolean}
     * @memberof RedMediaBinManagement
     */
    'TPVR': boolean;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'Type': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'UUID': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'Verifier': string;
    /**
     * 
     * @type {string}
     * @memberof RedMediaBinManagement
     */
    'Old_New_Seal': string;
}
/**
 * 
 * @export
 * @interface SerialsToHex
 */
export interface SerialsToHex {
    /**
     * 
     * @type {string}
     * @memberof SerialsToHex
     */
    'hex': string;
    /**
     * 
     * @type {string}
     * @memberof SerialsToHex
     */
    'Serials': string;
    /**
     * 
     * @type {string}
     * @memberof SerialsToHex
     */
    'CreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SerialsToHex
     */
    'Printer': string;
    /**
     * 
     * @type {string}
     * @memberof SerialsToHex
     */
    'hex_uuid': string;
}
/**
 * Update Pet attributes and identifier
 * @export
 * @interface UpdatePetRequestContent
 */
export interface UpdatePetRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePetRequestContent
     */
    'tag'?: string;
}
/**
 * Updated Pet
 * @export
 * @interface UpdatePetResponseContent
 */
export interface UpdatePetResponseContent {
    /**
     * 
     * @type {Pet}
     * @memberof UpdatePetResponseContent
     */
    'updated_pet': Pet;
}
/**
 * The identity of the caller
 * @export
 * @interface WhoAmIResponseContent
 */
export interface WhoAmIResponseContent {
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponseContent
     */
    'identity': string;
}

/**
 * CORSApi - axios parameter creator
 * @export
 */
export const CORSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsCall: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLocations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLocationsLocationid: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('corsLocationsLocationid', 'locationId', locationId)
            const localVarPath = `/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsParts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsRoomid: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('corsPartsRoomid', 'roomId', roomId)
            const localVarPath = `/parts/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPetsPetid: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('corsPetsPetid', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/red_bin_mgmt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmtOldNewSeal: async (oldNewSeal: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oldNewSeal' is not null or undefined
            assertParamExists('corsRedBinMgmtOldNewSeal', 'oldNewSeal', oldNewSeal)
            const localVarPath = `/red_bin_mgmt/{old_new_seal}`
                .replace(`{${"old_new_seal"}}`, encodeURIComponent(String(oldNewSeal)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/red_bin_mgmts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSerialstohexs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/serialsToHexs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSerialstohexsHexUuid: async (hexUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hexUuid' is not null or undefined
            assertParamExists('corsSerialstohexsHexUuid', 'hexUuid', hexUuid)
            const localVarPath = `/serialsToHexs/{hex_uuid}`
                .replace(`{${"hex_uuid"}}`, encodeURIComponent(String(hexUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsWhoami: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CORSApi - functional programming interface
 * @export
 */
export const CORSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CORSApiAxiosParamCreator(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsCall(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsCall(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsLocations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsLocations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsLocationsLocationid(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsLocationsLocationid(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsParts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsParts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsRoomid(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsRoomid(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPetsPetid(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPetsPetid(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRedBinMgmt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRedBinMgmt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRedBinMgmtOldNewSeal(oldNewSeal: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRedBinMgmtOldNewSeal(oldNewSeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsRedBinMgmts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsRedBinMgmts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSerialstohexs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSerialstohexs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSerialstohexsHexUuid(hexUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSerialstohexsHexUuid(hexUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsWhoami(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsWhoami(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CORSApi - factory interface
 * @export
 */
export const CORSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CORSApiFp(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsCall(options?: any): AxiosPromise<void> {
            return localVarFp.corsCall(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLocations(options?: any): AxiosPromise<void> {
            return localVarFp.corsLocations(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsLocationsLocationid(locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsLocationsLocationid(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsParts(options?: any): AxiosPromise<void> {
            return localVarFp.corsParts(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsRoomid(roomId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsRoomid(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPets(options?: any): AxiosPromise<void> {
            return localVarFp.corsPets(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPetsPetid(petId: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsPetsPetid(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmt(options?: any): AxiosPromise<void> {
            return localVarFp.corsRedBinMgmt(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmtOldNewSeal(oldNewSeal: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsRedBinMgmtOldNewSeal(oldNewSeal, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsRedBinMgmts(options?: any): AxiosPromise<void> {
            return localVarFp.corsRedBinMgmts(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSerialstohexs(options?: any): AxiosPromise<void> {
            return localVarFp.corsSerialstohexs(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSerialstohexsHexUuid(hexUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.corsSerialstohexsHexUuid(hexUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsWhoami(options?: any): AxiosPromise<void> {
            return localVarFp.corsWhoami(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CORSApi - object-oriented interface
 * @export
 * @class CORSApi
 * @extends {BaseAPI}
 */
export class CORSApi extends BaseAPI {
    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsCall(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsCall(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsLocations(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsLocations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsLocationsLocationid(locationId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsLocationsLocationid(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsParts(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsParts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsRoomid(roomId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsRoomid(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPets(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPetsPetid(petId: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPetsPetid(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRedBinMgmt(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRedBinMgmt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} oldNewSeal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRedBinMgmtOldNewSeal(oldNewSeal: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRedBinMgmtOldNewSeal(oldNewSeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsRedBinMgmts(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsRedBinMgmts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSerialstohexs(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSerialstohexs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {string} hexUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSerialstohexsHexUuid(hexUuid: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSerialstohexsHexUuid(hexUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsWhoami(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsWhoami(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLocationRequestContent} createLocationRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (createLocationRequestContent: CreateLocationRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationRequestContent' is not null or undefined
            assertParamExists('createLocation', 'createLocationRequestContent', createLocationRequestContent)
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPet: async (createPetRequestContent: CreatePetRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPetRequestContent' is not null or undefined
            assertParamExists('createPet', 'createPetRequestContent', createPetRequestContent)
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPetRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRedMediaBinManagementRequestContent} createRedMediaBinManagementRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedMediaBinManagement: async (createRedMediaBinManagementRequestContent: CreateRedMediaBinManagementRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRedMediaBinManagementRequestContent' is not null or undefined
            assertParamExists('createRedMediaBinManagement', 'createRedMediaBinManagementRequestContent', createRedMediaBinManagementRequestContent)
            const localVarPath = `/red_bin_mgmt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRedMediaBinManagementRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSerialsToHexRequestContent} createSerialsToHexRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSerialsToHex: async (createSerialsToHexRequestContent: CreateSerialsToHexRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSerialsToHexRequestContent' is not null or undefined
            assertParamExists('createSerialsToHex', 'createSerialsToHexRequestContent', createSerialsToHexRequestContent)
            const localVarPath = `/serialsToHexs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSerialsToHexRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('deletePet', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Location by id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getLocation', 'locationId', locationId)
            const localVarPath = `/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('getPet', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedMediaBinManagement: async (oldNewSeal: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oldNewSeal' is not null or undefined
            assertParamExists('getRedMediaBinManagement', 'oldNewSeal', oldNewSeal)
            const localVarPath = `/red_bin_mgmt/{old_new_seal}`
                .replace(`{${"old_new_seal"}}`, encodeURIComponent(String(oldNewSeal)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a SerialsToHex by id
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSerialsToHex: async (hexUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hexUuid' is not null or undefined
            assertParamExists('getSerialsToHex', 'hexUuid', hexUuid)
            const localVarPath = `/serialsToHexs/{hex_uuid}`
                .replace(`{${"hex_uuid"}}`, encodeURIComponent(String(hexUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotPartsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotPartsListByRoom: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('gotPartsListByRoom', 'roomId', roomId)
            const localVarPath = `/parts/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * -----------------------------Parts End------------------------------------------------------ ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        justToCall: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedMediaBinManagement: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/red_bin_mgmts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of SerialsToHexs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSerialsToHexs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/serialsToHexs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePet: async (petId: string, updatePetRequestContent: UpdatePetRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('updatePet', 'petId', petId)
            // verify required parameter 'updatePetRequestContent' is not null or undefined
            assertParamExists('updatePet', 'updatePetRequestContent', updatePetRequestContent)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePetRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLocationRequestContent} createLocationRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(createLocationRequestContent: CreateLocationRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateLocationResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(createLocationRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPet(createPetRequestContent: CreatePetRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPet(createPetRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRedMediaBinManagementRequestContent} createRedMediaBinManagementRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRedMediaBinManagement(createRedMediaBinManagementRequestContent: CreateRedMediaBinManagementRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRedMediaBinManagementResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRedMediaBinManagement(createRedMediaBinManagementRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSerialsToHexRequestContent} createSerialsToHexRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSerialsToHex(createSerialsToHexRequestContent: CreateSerialsToHexRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSerialsToHexResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSerialsToHex(createSerialsToHexRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Location by id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocationResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedMediaBinManagement(oldNewSeal: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRedMediaBinManagementResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedMediaBinManagement(oldNewSeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a SerialsToHex by id
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSerialsToHex(hexUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSerialsToHexResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSerialsToHex(hexUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gotPartsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GotPartsListResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gotPartsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gotPartsListByRoom(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GotPartsListByRoomResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gotPartsListByRoom(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * -----------------------------Parts End------------------------------------------------------ ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async justToCall(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JustToCallResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.justToCall(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLocations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLocationsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLocations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPetsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRedMediaBinManagement(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRedMediaBinManagementResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRedMediaBinManagement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of SerialsToHexs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSerialsToHexs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSerialsToHexsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSerialsToHexs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePetResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePet(petId, updatePetRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLocationRequestContent} createLocationRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(createLocationRequestContent: CreateLocationRequestContent, options?: any): AxiosPromise<CreateLocationResponseContent> {
            return localVarFp.createLocation(createLocationRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Pet
         * @param {CreatePetRequestContent} createPetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPet(createPetRequestContent: CreatePetRequestContent, options?: any): AxiosPromise<CreatePetResponseContent> {
            return localVarFp.createPet(createPetRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRedMediaBinManagementRequestContent} createRedMediaBinManagementRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedMediaBinManagement(createRedMediaBinManagementRequestContent: CreateRedMediaBinManagementRequestContent, options?: any): AxiosPromise<CreateRedMediaBinManagementResponseContent> {
            return localVarFp.createRedMediaBinManagement(createRedMediaBinManagementRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSerialsToHexRequestContent} createSerialsToHexRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSerialsToHex(createSerialsToHexRequestContent: CreateSerialsToHexRequestContent, options?: any): AxiosPromise<CreateSerialsToHexResponseContent> {
            return localVarFp.createSerialsToHex(createSerialsToHexRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet(petId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Location by id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(locationId: string, options?: any): AxiosPromise<GetLocationResponseContent> {
            return localVarFp.getLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Pet by id
         * @param {string} petId Pet identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet(petId: string, options?: any): AxiosPromise<GetPetResponseContent> {
            return localVarFp.getPet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oldNewSeal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedMediaBinManagement(oldNewSeal: string, options?: any): AxiosPromise<GetRedMediaBinManagementResponseContent> {
            return localVarFp.getRedMediaBinManagement(oldNewSeal, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a SerialsToHex by id
         * @param {string} hexUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSerialsToHex(hexUuid: string, options?: any): AxiosPromise<GetSerialsToHexResponseContent> {
            return localVarFp.getSerialsToHex(hexUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotPartsList(options?: any): AxiosPromise<GotPartsListResponseContent> {
            return localVarFp.gotPartsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotPartsListByRoom(roomId: string, options?: any): AxiosPromise<GotPartsListByRoomResponseContent> {
            return localVarFp.gotPartsListByRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * -----------------------------Parts End------------------------------------------------------ ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        justToCall(options?: any): AxiosPromise<JustToCallResponseContent> {
            return localVarFp.justToCall(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(options?: any): AxiosPromise<ListLocationsResponseContent> {
            return localVarFp.listLocations(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets(options?: any): AxiosPromise<ListPetsResponseContent> {
            return localVarFp.listPets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedMediaBinManagement(options?: any): AxiosPromise<ListRedMediaBinManagementResponseContent> {
            return localVarFp.listRedMediaBinManagement(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of SerialsToHexs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSerialsToHexs(options?: any): AxiosPromise<ListSerialsToHexsResponseContent> {
            return localVarFp.listSerialsToHexs(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Pet
         * @param {string} petId Pet identifier
         * @param {UpdatePetRequestContent} updatePetRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: any): AxiosPromise<UpdatePetResponseContent> {
            return localVarFp.updatePet(petId, updatePetRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * ---------------------------------------------------------------------------------------- Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: any): AxiosPromise<WhoAmIResponseContent> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {CreateLocationRequestContent} createLocationRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLocation(createLocationRequestContent: CreateLocationRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createLocation(createLocationRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Pet
     * @param {CreatePetRequestContent} createPetRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPet(createPetRequestContent: CreatePetRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPet(createPetRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRedMediaBinManagementRequestContent} createRedMediaBinManagementRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createRedMediaBinManagement(createRedMediaBinManagementRequestContent: CreateRedMediaBinManagementRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createRedMediaBinManagement(createRedMediaBinManagementRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSerialsToHexRequestContent} createSerialsToHexRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSerialsToHex(createSerialsToHexRequestContent: CreateSerialsToHexRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSerialsToHex(createSerialsToHexRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Pet by id
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePet(petId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Location by id
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLocation(locationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Pet by id
     * @param {string} petId Pet identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPet(petId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oldNewSeal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRedMediaBinManagement(oldNewSeal: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRedMediaBinManagement(oldNewSeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a SerialsToHex by id
     * @param {string} hexUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSerialsToHex(hexUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSerialsToHex(hexUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gotPartsList(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gotPartsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomId -----------------------------Parts Start----------------------------------------------------
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gotPartsListByRoom(roomId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gotPartsListByRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * -----------------------------Parts End------------------------------------------------------ ---------------------------------------------------------------------------------------- Get the Identity of the API caller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public justToCall(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).justToCall(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLocations(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listLocations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Pets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPets(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRedMediaBinManagement(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRedMediaBinManagement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of SerialsToHexs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSerialsToHexs(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSerialsToHexs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Pet
     * @param {string} petId Pet identifier
     * @param {UpdatePetRequestContent} updatePetRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePet(petId: string, updatePetRequestContent: UpdatePetRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePet(petId, updatePetRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ---------------------------------------------------------------------------------------- Get the Identity of the API caller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public whoAmI(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}


