import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import ApiFactory from '../../open-api/ApiFactory';
import { SerialsToHex } from '../../open-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Link from "@amzn/awsui-components-react/polaris/link";
import Container from "@amzn/awsui-components-react/polaris/container";
import Clock from "../Clock";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {Toggle} from "@amzn/awsui-components-react";
import {PhoneToolBigImg} from "../Helper";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import * as React from "react";

export default function SerialsToHexsTable() {
    const [allItems, setAllItems] = useState<SerialsToHex[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [footer, setFooter] = useState('');
    const history = useHistory();
    const createButton = (
        <Button variant="primary" iconName="add-plus" href={`#/serialsToHexs/create`}>
            Create
        </Button>
    );
    const SerialsToHexsApi = ApiFactory();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setAllItems( ((await SerialsToHexsApi.listSerialsToHexs()).data.serialsToHexs ));
            }
            catch(error: any) {
                setFooter(`Error: ${error?.response?.data?.message || 'Unable to load data'}`);
            } finally {
                setLoading(false);
            }
        })();
    }, [refreshTableFlag]);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={createButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const { selectedItems } = collectionProps;
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        info={<Link>Info</Link>}
                        description="View RFID Tag"

                    >
                        Serials To Hex
                    </Header>


                </SpaceBetween>
            }
        >
            <Table
                {...collectionProps}
                loading={loading}
                loadingText="Loading instances"
                selectionType="single"
                footer={footer}
                header={
                    <Header
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                        actions={
                            <SpaceBetween direction="horizontal" size="m">
                                {/*<Button*/}
                                {/*    iconName="status-negative"*/}
                                {/*    disabled={selectedItems!.length == 0}*/}
                                {/*    onClick={() => deletePet(selectedItems![0].petId)}*/}
                                {/*>*/}
                                {/*    Delete*/}
                                {/*</Button>*/}

                                {/*<Button*/}
                                {/*    iconName="edit"*/}
                                {/*    disabled={selectedItems!.length == 0}*/}
                                {/*    onClick={() => editPet(selectedItems![0].petId)}*/}
                                {/*>*/}
                                {/*    Edit*/}
                                {/*</Button>*/}

                                {createButton}
                            </SpaceBetween>
                        }
                    >
                        Records
                    </Header>
                }
                columnDefinitions={COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringAriaLabel="Filter records"
                    />
                }
            />
        </ContentLayout>

    );
}
