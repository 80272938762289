import React, { useEffect } from 'react'
import useStateRef from 'react-usestateref'
import Badge from '@amzn/awsui-components-react/polaris/badge'
import moment from 'moment'
import { SpaceBetween } from '@amzn/awsui-components-react'

export default function Clock() {
    const [time, setTime] = useStateRef('')
    const [weekNumber, setWeekNumber] = useStateRef('')

    const currentWeekNumber = () => {
        setTime(`${moment().format("dddd, MMMM Do YYYY - h:mm:ss a")}`)
        setWeekNumber(`Week No: ${moment().week()}`)
    }

    useEffect(() => {
        setTimeout(function () { currentWeekNumber() }, 1000)
    }, [time])

    return <SpaceBetween direction='horizontal' size='xxs'>
        <Badge color='blue'>{String(time)}</Badge>
        <Badge>{weekNumber}</Badge>
    </SpaceBetween>
}