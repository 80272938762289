import * as React from "react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import Link from "@amzn/awsui-components-react/polaris/link";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import CycleCount1LocationSelectionPage from './CycleCount1LocationSelectionPage'
import Select from "@amzn/awsui-components-react/polaris/select";
import ApiFactory from "../../open-api/ApiFactory";
import {useEffect, useState} from "react";
import PartsCountingList from "./PartsCountingList";
import CycleReason from "./CycleReason";
import {Part} from "../../open-api/generated-src";
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";

export default () => {
    const LocationsApi = ApiFactory();
    const [selectedId, setSelectedId] = React.useState(
        "DCO_SPARE"
    );

    const [selectedLocationOption, setSelectedLocationOption] = React.useState( {
        label: 'Please Select Location',
        value: '000'
    });

    const [selectedCategoryOption, setSelectedCategoryOption] = React.useState( {
        label: 'Please Select Category',
        value: '000'
    });

    const [value, setValue] = React.useState("");

    const [isLoadingNextStep, setisLoadingNextStep] = React.useState(true)

    const initPartDate: Part[]=[{
        serial_id: 'Loading',
        site: 'Loading',
        room: 'Loading,',
        bin: 'Loading',
        type_name: 'Loading',
        found: 'Loading'
    }]
    const [allItems, setAllItems] = useState<Part[]>(initPartDate);
    interface CustomOption {
        value: string;
        label: string;
    }
    const [optionss, setOptions] = React.useState<[{}]>([{}]);

    async function roomOnchange(selectedLocationOption){
        setisLoadingNextStep(true)
        setSelectedLocationOption(selectedLocationOption as CustomOption);
        const roomId = selectedLocationOption.label
        const parts = (await LocationsApi.gotPartsListByRoom(roomId)).data.parts
        setAllItems(parts)
        setisLoadingNextStep(false)
    }


    useEffect(() => {
        (async () => {
            try {
                const options = (await LocationsApi.listLocations()).data.locations;
                options.forEach((distro) => {
                    optionss.push({label: distro.Room, value: distro.locationId});
                });
            }
            catch(error: any) {
                alert(error)
            }
        })();
    }, []);


    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);
    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber =>
                    `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                    `Step ${stepNumber} of ${stepsCount}`,
                navigationAriaLabel: "Steps",
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: "Submit CycleCount",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            isLoadingNextStep={isLoadingNextStep}
            steps={[
                {
                    title: "Location Selection (1/3)",
                    info: <Link variant="info">Info</Link>,
                    description:
                        "Please select location you want to cycle count",
                    content: (
                        <Container
                            header={
                                <Header variant="h2">
                                    Location && Category
                                </Header>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">

                                <SegmentedControl
                                    selectedId={selectedId}
                                    onChange={({ detail }) =>
                                        setSelectedId(detail.selectedId)
                                    }
                                    label="Default segmented control"
                                    options={[
                                        { text: "DCO_SPARE", id: "DCO_SPARE" },
                                        { text: "BUILD", id: "BUILD" },
                                        { text: "PROJECT", id: "PROJECT" },
                                        { text: "CRITICAL_SPARE", id: "CRITICAL_SPARE" }
                                    ]}
                                />
                                <Select
                                    selectedOption={selectedLocationOption}
                                    onChange={
                                        ({ detail: { selectedOption } }) => {roomOnchange(selectedOption)  }

                                    }
                                    options={optionss}
                                    selectedAriaLabel="Selected"
                                />

                            </SpaceBetween>

                        </Container>

                    )
                },
                {
                    title: "Counting (2/3)",
                    description:
                        "Please start counting using the Smartlog Application",
                    content: (
                        <Container
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <PartsCountingList part={allItems}/>
                            </SpaceBetween>
                        </Container>
                    ),
                },
                {
                    title: "Reason (3/3)",
                    description:
                        "Please Fill in the reason for the Cycle Count",
                    content: (
                        <Container>
                            <SpaceBetween direction="vertical" size="l">
                                <CycleReason part={allItems}/>
                            </SpaceBetween>
                        </Container>
                    )}
                // },
                // {
                //     title: "Summary (4/4)",
                //     content: (
                //         <SpaceBetween size="xs">
                //
                //         </SpaceBetween>
                //     )
                // }
            ]}
        />
    );
}