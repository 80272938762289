import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {SerialsToHex} from '../../open-api/generated-src';
import { ReactNode } from 'react';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<SerialsToHex>[] = [
  {
    id: 'location',
    header: 'Location',
    cell: item => item.hex
  },
  {
    id: 'username',
    cell: item => item.Serials,
    header: 'Username'
  },
  {
    id: 'createdAt',
    cell: item => item.CreatedAt,
    header: '\tCycle count Date'
  },
  {
    id: 'week',
    header: 'Cycle Count week',
    cell: item => item.Printer
  },
  {
    id: 'detect%',
    header: 'Detect %',
    cell: item => item.Printer
  },
  {
    id: 'detectQty',
    header: 'Detect Qty',
    cell: item => item.Printer
  },
  {
    id: 'notDetectQty',
    header: 'Qty Not Detect',
    cell: item => item.Printer
  },
  {
    id: 'extra',
    header: 'Extra Tags',
    cell: item => item.Printer
  },
  {
    id: 'brokenTags',
    header: 'Broken tags',
    cell: item => item.Printer
  },
  {
    id: 'extra',
    header: 'Extra Tags',
    cell: item => item.Printer
  },
  {
    id: 'Missing',
    header: 'Missing',
    cell: item => item.Printer
  },
  {
    id: 'Others',
    header: 'Others',
    cell: item => item.Printer
  },
  {
    id: 'withoutTags',
    header: 'Parts Without tags',
    cell: item => item.Printer
  },
  {
    id: 'Storage',
    header: 'Storage',
    cell: item => item.Printer
  },
  {
    id: 'notReadable',
    header: 'Tag not readable',
    cell: item => item.Printer
  },
  {
    id: 'totalResult',
    header: 'Total Cycle Count Result',
    cell: item => item.Printer
  }
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding="m" color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
