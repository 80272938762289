import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { RedMediaBinManagement } from '../../open-api/generated-src';
import { ReactNode } from 'react';
import {Confirmed, LoginBadge, scanStatus} from "../Helper";
import Link from "@amzn/awsui-components-react/polaris/link";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<RedMediaBinManagement>[] = [
  {
    id: 'Bin Name/Asset',
    header: 'Bin Name/Asset',
    cell: item => item.BinNameAsset
  },
  {
    id: 'Bin Location',
    cell: item => item.BinLocation,
    header: 'Bin Location'
  },
  {
    id: 'Type',
    cell: item => scanStatus(item.Type),
    header: 'Type'
  },
  {
    id: 'Media Count',
    header: 'Media Count',
    cell: item => item.MediaCount
  },
  {
    id: 'NewSealNumber',
    header: 'NewSealNumber',
    cell: item => item.NewSealNumber
  },
  {
    id: 'OldSealNumber',
    header: 'OldSealNumber',
    cell: item => item.OldSealNumber
  },
  {
    id: 'Primary',
    header: 'Primary',
    cell: item => LoginBadge(item.Primary)
  },
  {
    id: 'Verifier',
    header: 'Verifier',
    cell: item => LoginBadge(item.Verifier)
  },
  {
    id: 'TPVR?',
    header: 'TPVR?',
    cell: item => Confirmed(item.TPVR.toString())
  },
  {
    id: 'Ticket',
    header: 'Ticket',
    cell: item => <Box ><Link external href={item.Ticket}>{item.Ticket}</Link></Box>

  }
];

export function getMatchesCountText(count: number){
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding="m" color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
