import IndexRouter from '../Router/IndexRouter'
import SelfTopNavigation from '../components/topNavigation/topNavigation'

export default function App() {
    return (
        <div>
            <SelfTopNavigation></SelfTopNavigation>
            <IndexRouter></IndexRouter>
        </div>
    );
}
