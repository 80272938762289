import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation/navigation";
import './styles.scss';

import RED_BIN from './redbin';

export default function Homepage() {
  return (
      <AppLayout
          content={<RED_BIN/>}
          contentType="default"
          navigationHide={false}
          navigation={<ServiceNavigation />}
          toolsHide={true}
          disableContentPaddings={false}
      />
  );
}
