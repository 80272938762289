import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import ApiFactory from '../../open-api/ApiFactory';
import { SerialsToHex } from '../../open-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Link from "@amzn/awsui-components-react/polaris/link";
import Container from "@amzn/awsui-components-react/polaris/container";
import Clock from "../Clock";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {Toggle} from "@amzn/awsui-components-react";
import {PhoneToolBigImg} from "../Helper";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import * as React from "react";

export default function CycleCountResult() {
    const [allItems, setAllItems] = useState<SerialsToHex[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [footer, setFooter] = useState('');
    const history = useHistory();



    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                noMatch: (
                    <EmptyState
                        title="No Cycle Count records"
                        subtitle="No Cycle Count records."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const { selectedItems } = collectionProps;
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        info={<Link>Info</Link>}
                        description="View records"
                    >
                        Cycle Count Result
                    </Header>


                </SpaceBetween>
            }
        >
            <Table
                {...collectionProps}
                loading={loading}
                loadingText="Loading instances"
                selectionType="single"
                footer={footer}
                header={
                    <Header
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                    >
                    </Header>
                }
                columnDefinitions={COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringAriaLabel="Filter records"
                    />
                }
            />
        </ContentLayout>

    );
}
