import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import ApiFactory from '../../open-api/ApiFactory';
import {RedMediaBinManagement} from '../../open-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

export default function PetsTable() {
    const [allItems, setAllItems] = useState<RedMediaBinManagement[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [footer, setFooter] = useState('');
    const history = useHistory();
    const createButton = (
        <Button variant="primary" iconName="add-plus" href={`#/form/create`}>
            Create
        </Button>
    );

    const ScansApi = ApiFactory();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setAllItems( (await ScansApi.listRedMediaBinManagement()).data.redMediaBinManagements);
            }
            catch(error: any) {
                setFooter(`Error: ${error?.response?.data?.message || 'Unable to load data'}`);
            } finally {
                setLoading(false);
            }
        })();
    }, [refreshTableFlag]);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={createButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 50
            },
            selection: {}
        }
    );

    async function deletePet(petId: string) {
        await ScansApi.deletePet(petId);
        setRefreshTableFlag(!refreshTableFlag);
    }

    function editPet(petId: string) {
        history.push(`/form/edit/${petId}`);
    }

    const { selectedItems } = collectionProps;
    return (
        <Table
            {...collectionProps}
            loading={loading}
            loadingText="Loading instances"
            selectionType="single"
            footer={footer}
            header={
                <Header
                    counter={
                        allItems &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                    }
                >
                    Records
                </Header>
            }
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
    );
}
